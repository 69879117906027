/*
 * Copyright (C) 2020 qnnp. All rights reserved.
 * 项目名称：zs.jscj.edu.cn
 * 文件名称：StateAssign.js
 * Date: 2020/8/20 下午2:13
 * Author:qnnp
 */

function StateAssign(oldData,newData){
	return JSON.parse(JSON.stringify(Object.assign(oldData,newData)))
}
export default StateAssign
