/*
 * Copyright (C) 2020 qnnp. All rights reserved.
 * 项目名称：zs.jscj.edu.cn
 * 文件名称：Post.js
 * Date: 2020/8/18 上午10:42
 * Author:qnnp
 */
import React, {
	useEffect,
	useState
}                     from "react";
import {Grid}         from "@material-ui/core";
import {useParams}    from "react-router-dom";
import Fade           from "@material-ui/core/Fade";
import POST           from "../Common/POST";
import Conf           from "../Common/Conf";
import moment         from "moment";
import PublishIcon    from '@material-ui/icons/Publish';
import VisibilityIcon from '@material-ui/icons/Visibility';
import GetAppIcon     from "@material-ui/icons/GetApp";
import "babel-polyfill";

function Post(props) {
	let {PostType, PostID}      = useParams()
	let [PostData, setPostData] = useState([])
	useEffect(() => {
		props.setState({
			               ShowSidebar: Conf().MobileDevice
		               })
		// setFadein(true)
		// return () => setFadein(false)
		POST('/article/findInfo', {
			type: PostType,
			id  : PostID || 1
		})
			.then(data => {
				if (data.status === 200) {
					setPostData(data.data)
					console.log(data.data)
					props.setState({
						               title: data.data.title,
					               })
				}
			})
	}, [PostType])
	//Post输出
	return (
		<Fade in={true}>
			<Grid container className={'PageGridRoot'}>
				<Grid container item className={'PageGridMain'}>
					<div style={{width: '100%'}}>
						<h2 style={{
							textAlign: "center",
							width    : '100%'
						}}>{PostData.title}</h2>
						<div style={{
							marginBottom : '20px',
							color        : '#999',
							textAlign    : "center",
							width        : '100%',
							boxShadow    : '0 6px 3px -3px #eee',
							paddingBottom: '10px'
						}}>
							<PublishIcon style={{
								fontSize    : '16px',
								marginBottom: '-3px',
								marginRight : '3px'
							}} />{moment(PostData.addTime)
							.format('YYYY年MM月DD日')}&emsp;
							<VisibilityIcon style={{
								fontSize    : '16px',
								marginBottom: '-3px',
								marginRight : '3px'
							}} />{PostData.viewtimes}次
						</div>
						<div id={'PostContent'} dangerouslySetInnerHTML={{__html: PostData.content}} />
					</div>
				</Grid>
			</Grid>
		</Fade>
	)
}
export default Post;
