/*
 * Copyright (C) 2020 qnnp. All rights reserved.
 * 项目名称：zs.jscj.edu.cn
 * 文件名称：Footer.js
 * Date: 2020/8/18 上午10:42
 * Author:qnnp
 */
import {Grid}                       from "@material-ui/core";
import './Style/Footer.css'
import Slide                        from "@material-ui/core/Slide";
import React, {useEffect, useState} from "react";
import Conf                         from "./Conf";
import POST                         from "./POST";
import "babel-polyfill";

function Footer(props) {
  let [State, setState] = useState({})
  let [ContactInfo, setContactInfo] = useState([])
  let [ShowContactInfo, setShowContactInfo] = useState(true)
  let [LinkList1, setLinkList1] = useState([])
  let [LinkList2, setLinkList2] = useState([])
  let [LinkList3, setLinkList3] = useState([])
  let [LinkList4, setLinkList4] = useState([])
  useEffect(() => {
    POST('/visit/visitNumber')
      .then(data => setState(data.data))
    POST('/other/otherList', {type: 14})
      .then(data => setContactInfo([
                                     ...(
                                       data.data || []
                                     ),
                                   ].filter(item => item.img)))
    //本校院系及专业
    POST('/link/findList', {type: 9})
      .then(data => setLinkList1(data.data))
    //本校机关及部门
    POST('/link/findList', {type: 10})
      .then(data => setLinkList2(data.data))
    //相关招生网站
    POST('/link/findList', {type: 11})
      .then(data => setLinkList3(data.data))
    //兄弟院校连接
    POST('/link/findList', {type: 12})
      .then(data => setLinkList4(data.data))
  }, [])
  //Footer输出
  return (
    <Slide
      direction={"up"}
      in={true}
    >
      <Grid container id={'Footer'} className={'PageGridRoot'} style={{padding: '15px'}}>
        <Grid container item className={'PageGridMain'} style={{alignItems: "start"}}>
          <Grid container item xs={Conf().MobileDevice ? 3 : 12} className={'LinksBox'}>
            <h5>本校院系及专业</h5>
            <ul>
              {LinkList1.map(
                link =>
                  <li key={link.linksId}><a href={link.linksUrl} target={'_blank'}>{link.linksText}</a></li>,
              )}
            </ul>
          </Grid>
          <Grid container item xs={Conf().MobileDevice ? 3 : 12} className={'LinksBox'}>
            <h5>本校机关及部门</h5>
            <ul>
              {LinkList2.map(
                link =>
                  <li key={link.linksId}><a href={link.linksUrl} target={'_blank'}>{link.linksText}</a></li>,
              )}
            </ul>
          </Grid>
          <Grid container item xs={Conf().MobileDevice ? 3 : 12} className={'LinksBox'}>
            <h5>相关招生网站</h5>
            <ul>
              {LinkList3.map(
                link =>
                  <li key={link.linksId}><a href={link.linksUrl} target={'_blank'}>{link.linksText}</a></li>,
              )}
            </ul>
          </Grid>
          <Grid container item xs={Conf().MobileDevice ? 3 : 12} className={'LinksBox'}>
            <h5>兄弟院校连接</h5>
            <ul>
              {LinkList4.map(
                link =>
                  <li key={link.linksId}><a href={link.linksUrl} target={'_blank'}>{link.linksText}</a></li>,
              )}
            </ul>
          </Grid>
          {/*页脚信息*/}
          <Grid
            container item xs={12} id={'FooterInfo'}
            style={{
              marginTop: '30px',
            }}
          >
            当日访问量：{State.num},&nbsp;总访问量：{State.totalNum}.&nbsp;
            粤ICP备08007532号<br />
            &copy;{(
            new Date()
          ).getFullYear()} {props.State.SchoolName}<br /><br />
          </Grid>
        </Grid>
        {ContactInfo.length && <div
          style={{
            position: "fixed",
            right   : '15px',
            bottom  : '30px',
            // top            : '50%',
            // transform      : 'translateY(-50%)',
            width          : '200px',
            maxWidth       : '30%',
            boxShadow      : '0 0 10px #e6e6e6',
            padding        : '10px',
            backgroundColor: '#FFF',
            zIndex         : '9999',
            borderRadius   : '5px',
            display        : ShowContactInfo ? 'block' : 'none',
          }}
        >
          {/*关闭*/}
          <div
            onClick={() => setShowContactInfo(false)}
            style={{
              position       : "absolute",
              top            : '-10px',
              left           : '-10px',
              backgroundColor: '#999',
              color          : '#FFF',
              borderRadius   : '50%',
              textAlign      : "center",
              lineHeight     : '25px',
              width          : '25px',
              fontSize       : '25px',
              verticalAlign  : "top",
              cursor         : "pointer",
            }}
          >&times;</div>
          {ContactInfo.sort((a, b) => a.sort - b.sort).map(
            item =>
              <div style={{width: '100%'}} key={item.id}>
                <h3
                  style={{
                    width       : '100%',
                    textAlign   : "center",
                    borderBottom: '1px #e6e6e6 solid',
                  }}
                >{item.name}</h3>
                <img
                  src={item.img} alt={''}
                  style={{
                    width    : '100%',
                    boxShadow: '0 0 5px #e6e6e6',
                  }}
                />
              </div>,
          )}
        </div>}
      </Grid>
    </Slide>
  )
}

export default Footer;
