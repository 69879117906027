/*
 * Copyright (C) 2020 qnnp. All rights reserved.
 * 项目名称：zs.jscj.edu.cn
 * 文件名称：SignUpQuery.js
 * Date: 2020/11/13 下午3:04
 * Author:qnnp
 */
import React, {
	useEffect,
	useState
}             from "react";
import Conf   from "../Common/Conf";
import {
	Button,
	Col,
	Form,
	Input,
	message,
	Modal,
	Row
}             from "antd";
import POST   from "../Common/POST";
import moment from "moment";

function TZZkz(props) {
	const [theTime, settheTime] = useState(moment()
											   .format("YYYYMMDDHHmmss"))
	useEffect(() => {
		props.setState({
						   title      : '社会招生报名结果查询和准考证打印',
						   ShowSidebar: Conf().MobileDevice //判断移动设备
					   })
		setTimeout(() => window.scrollTo(0, 0))
	}, [])
	const [MainForm] = Form.useForm();
	function Submit(data) {
		if (!data.xtsfzh) {
			return message.error('请输入身份证号')
		}
		if (!data.sjh) {
			return message.error('请输入手机号码')
		}
		if (!data.authCode) {
			return message.error('请输入验证码')
		}
		POST("/student/findUnifiedRecruitment", data, {}, true)
			.then(res => {
				if (res.status && res.status === 200) {
					console.log(res)
					message.success('查询成功')
					localStorage.clear();
					localStorage.setItem(`?${theTime}`, JSON.stringify(res.data))
					const modal = Modal.confirm(
						{
							title     : "查询结果",
							content   : `您好：${res.data.xm},您的信息已查询成功，请及时打印准考证！`,
							cancelText: "继续查询",
							okText    : "立即打印准考证",
							onOk() {
								window.open('/tzzkz.html?' + theTime, '', `menubar=no,location=no,resizable=yes,scrollbars=yes,status=no`)
								modal.destroy();
							}
						}
					)
				} else {
					res.message !== null && Modal.error(
						{
							title  : `查询结果`,
							content: `查询结果：${res.message}`
						}
					)
				}
				// MainForm.resetFields()
			})
	}
	const [Phone, setPhone]           = useState("")
	const [CodeStatus, setCodeStatus] = useState(0)
	useEffect(() => {
		if (CodeStatus > 0) {
			setTimeout(() => {
				setCodeStatus(CodeStatus - 1)
			}, 1000)
		}
	}, [CodeStatus])
	return (
		<Row
			justify={"center"}
			style={{
				width : "100%",
				margin: "30px 20px"
			}}
		>
			<Col
				span={24} style={{
				maxWidth: "400px",
			}}
			>
				<Form
					form={MainForm}
					onFinish={Submit}
					layout={"vertical"}
					// labelCol={{span: 7}}
					// wrapperCol={{span: 15}}
					initialValues={{
						// xxfs: "全日制在校",
						zy: "电子商务",
					}}
				>
					<Form.Item
						label={"身份证号"}
						name={"xtsfzh"}
						children={
							<Input
								placeholder={"请输入身份证号"}
								size={"large"}
								type={"text"}
								onChange={e => {
									// setPhone(e.target.value)
								}}
							/>
						}
					/>
					<Row justify={"space-between"}>
						<Col span={24}>
							<Form.Item
								label={"手机号"}
								name={"sjh"}
								children={
									<Input
										placeholder={"请输入联系手机号码"}
										size={"large"}
										type={"text"}
										onChange={e => {
											setPhone(e.target.value)
										}}
									/>
								}
							/>
						</Col>
						<Col span={11}>
							<Form.Item
								name={"authCode"}
								children={<Input placeholder={"验证码"} size={"large"} type={"text"} />}
							/>
						</Col>
						<Col span={11}>
							<Button
								block
								type={"text"}
								onClick={() => {
									POST("/sms/sms", {phone: Phone}, {}, false)
										.then(res => {
											if (res.status === 200) {
												setCodeStatus(60)
											} else {
												message.info(res.message)
											}
										})
								}}
								disabled={CodeStatus}
								size={"large"}
							>
								获取验证码{CodeStatus === 0 || `(${CodeStatus})`}
							</Button>
						</Col>
						<Col style={{marginTop: "20px"}} />
						<Col span={11}>
							
							<Button
								type={"text"}
								block
								size={"large"}
								onClick={() => {
									MainForm.resetFields()
								}}
							>重新填</Button>
						</Col>
						<Col span={11}>
							
							<Button
								type={"primary"}
								block
								size={"large"}
								htmlType={"submit"}
							>提交信息</Button>
						</Col>
					</Row>
				</Form>
			</Col>
		</Row>
	)
}
export default TZZkz;
