/*
 * Copyright (C) 2020 qnnp. All rights reserved.
 * 项目名称：zs.jscj.edu.cn
 * 文件名称：Banner.js
 * Date: 2020/8/18 上午10:42
 * Author:qnnp
 */
import React, {
	useEffect,
	useState
}                   from "react";
import {
	Avatar,
	Grid
}                   from "@material-ui/core";
import {useHistory} from "react-router-dom";
import Conf         from "./Conf";

function Banner(props) {
	let history                 = useHistory();
	let [LogoSize, setLogoSize] = useState(0)
	useEffect(() => {
		window.onresize = () => {
			setLogoSize(document.querySelector('#HeaderLogo').offsetWidth - 20)
		}
		setLogoSize(document.querySelector('#HeaderLogo').offsetWidth - 20)
	}, [])
	//Banner输出
	return (
		<Grid container id={'HeaderBanner'} className={'PageGridRoot'}>
			<Grid
				container item className={'PageGridMain'}
				onClick={() => {history.push('/')}}
			>
				<Grid
					container item xs={Conf().MobileDevice ? 1 : 2} id={'HeaderLogo'}
				>
					<Avatar
						
						src={'/logo.png'}
						style={{
							width : LogoSize + 'px',
							height: LogoSize + 'px',
							// maxWidth       : 'calc( 100% - 20px )',
							maxWidth : '80px',
							maxHeight: '80px',
							margin   : '20% 10px',
						}}
					/>
				</Grid>
				<Grid
					container
					item
					xs={Conf().MobileDevice ? 8 : 6}
					style={{
						flexDirection : "column",
						justifyContent: "center"
					}}
				>
					<img
						src={'/logoText.png'}
						style={{
							width   : '90%',
							maxWidth: '300px'
						}}
						alt={''}
					/>
				</Grid>
				<Grid
					container item
					xs={Conf().MobileDevice ? 3 : 4}
					// xs={4}
					style={{justifyContent: "center", flexDirection: "column", alignItems: 'flex-end'}}
				>
					{/*<Avatar*/}
					{/*	variant={"square"}*/}
					{/*	src={require('../subLogo.png')}*/}
					{/*	style={{*/}
					{/*		width          : '100%',*/}
					{/*		maxWidth       : 'calc( 100% - 20px )',*/}
					{/*		margin         : '30px 10px',*/}
					{/*	}}*/}
					{/*/>*/}
					<img src={'/subLogo.png'} style={{width: '100%', maxWidth: '200px', float: "right"}} />
				</Grid>
			</Grid>
		</Grid>
	)
}
export default Banner;
