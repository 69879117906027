/*
 * Copyright (C) 2020 qnnp. All rights reserved.
 * 项目名称：zs.jscj.edu.cn
 * 文件名称：NotFound.js
 * Date: 2020/8/21 下午1:22
 * Author:qnnp
 */

import React  from "react";
import {Link} from "react-router-dom";

function NotFound(){
	return(
		<div style={{
			width:'100%',
			textAlign:"center"
		}}>
			<h1 style={{
				width:'100%',
				color:'#666',
				textAlign:'center'
			}}>404 - 您查询的页面不存在...</h1>
			<span
				style={{
					color:'#999'
				}}
			>可能由于网站升级造成路径改变造成、请去往<Link to={'/'}>首页</Link>查询！</span>
		</div>
	)
}
export default NotFound;
