/*
 * Copyright (C) 2020 qnnp. All rights reserved.
 * 项目名称：zs.jscj.edu.cn
 * 文件名称：SignUpCat.js
 * Date: 2020/12/8 下午12:07
 * Author:qnnp
 */
import React, {
	useEffect,
	useState
}           from "react";
import {
	Button,
	Col,
	Descriptions,
	Form,
	Input,
	message,
	Row
}           from "antd";
import Conf from "../Common/Conf";
import POST from "../Common/POST";
const TZQuery = props => {
	const [theForm]              = Form.useForm();
	const [theResult, setResult] = useState({})
	useEffect(() => {
		props.setState({
						   title      : '社会招生录取查询',
						   ShowSidebar: Conf().MobileDevice //判断移动设备
					   })
		setTimeout(() => window.scrollTo(0, 0))
	}, [])
	const Query      = data => {
		POST('/student/tqzsAdmissionQuery', data, {}, false)
			.then(res => {
				if (res.status === 200) {
					res.data.lqqk =
						res.data.lqqk === '拟录取'
						? <span
							style={{
								color: 'red'
							}}
						>
											 恭喜你已经被江苏财经职业技术学院录取
									  </span>
						: res.data.lqqk === '不录取'
						  ? <span
							  style={{
								  color: '#1890ff'
							  }}
						  >
												 很遗憾，你未被江苏财经职业技术学院录取
										</span>
						  : res.data.lqqk
					setResult(res.data)
					message.success(res.message)
				} else {
					res.message && message.error(res.message)
				}
			})
	}
	const resultText = {
		'zcj' : '总成绩',
		'ffcj': '赋分成绩',
		'yw'  : '语文',
		'sx'  : '数学',
		'wy'  : '外语'
	}
	return (
		<Row
			style={{
				width         : '100%',
				justifyContent: 'center',
				paddingTop    : '30px',
			}}
		>
			<Col span={22} style={{maxWidth: '400px'}}>
				<Form
					style={{}}
					form={theForm}
					onFinish={Query}
					labelCol={{span: 6}}
					wrapperCol={{span: 18}}
					requiredMark={"optional"}
				>
					<Form.Item
						name={'xm'}
						required
						label={'姓名'}
						rules={[
							{
								required: true,
								message : '请输入姓名'
							}
						]}
					>
						<Input />
					</Form.Item>
					<Form.Item
						name={'xtsfzh'}
						label={'身份证号'}
						rules={[
							{
								required: true,
								message : '请输入身份证号码'
							}
						]}
					>
						<Input />
					</Form.Item>
					<Row justify={'space-between'} style={{width: '100%'}}>
						<Col span={11}>
							<Button
								block type={"ghost"} onClick={() => {
								theForm.resetFields();
								setResult({})
							}}
							>重新查询</Button>
						</Col>
						<Col span={11}>
							<Button block type={"primary"} htmlType={"submit"}>查询</Button>
						</Col>
					</Row>
				</Form>
			</Col>
			<Col span={22}>
				{Object.keys(theResult).length > 0 &&
				 <Row
					 style={{
						 width    : '100%',
						 marginTop: '30px'
					 }} justify={'center'}
				 >
					 <Col span={22}>
						 <Descriptions
							 title={
								 <div
									 style={{
										 position  : "relative",
										 whiteSpace: "normal",
										 textAlign : "center"
									 }}
								 >
									 查询结果：
									 {theResult.lqqk === '拟录取'
									  ? <span
										  style={{
											  color: 'red'
										  }}
									  >
											 恭喜你已经被江苏财经职业技术学院录取
									  </span>
									  : theResult.lqqk === '不录取'
										? <span
											style={{
												color: '#1890ff'
											}}
										>
												 很遗憾，你未被江苏财经职业技术学院录取
										</span>
										: theResult.lqqk}
								 </div>
							 } bordered column={1}
						 >
							 {Object
								 .keys(resultText)
								 .map(
									 field =>
										 resultText[field] && theResult[field] &&
										 <Descriptions.Item key={field} label={resultText[field]}>
											 {theResult[field] || ''}
										 </Descriptions.Item>)}
						 </Descriptions>
					 </Col>
				 </Row>}
			</Col>
		</Row>
	)
}
export default TZQuery;
