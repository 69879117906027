/*
 * Copyright (C) 2020 qnnp. All rights reserved.
 * 项目名称：zs.jscj.edu.cn
 * 文件名称：Header.js
 * Date: 2020/8/18 上午10:42
 * Author:qnnp
 */
import React, {
	useEffect,
	useState
}                from "react";
import {Grid}    from "@material-ui/core";
import {NavLink} from "react-router-dom";
import './Style/Header.css'
import Banner    from "./Banner";
import Slide     from "@material-ui/core/Slide";
import Conf      from "./Conf";
import {Menu}    from "@material-ui/icons";

function Header(props) {
	let [MenuItemStyle, setMenuItemStyle] = useState({})
	let [MenuStyle, setMenuStyle]         = useState({})
	let [MenuShow, setMenuShow]           = useState('0')
	useEffect(() => {
		window.onkeydown = e => {
			if (e.key === 'F7' || e.code === 'F7' || e.keyCode === 118 || e.which === 118) {
				// window.open(`http://114.214.87.100:8090/caijing/login`, '_blank')
				window.open(`/caijing/login`, '_blank')
			}
		}
		window.onclick = e=>{
			// console.log(e)
			// Conf().MobileDevice||setMenuShow('0')
		}
	}, [])
	useEffect(() => {
		if (!Conf().MobileDevice) {
			setMenuStyle({
				             height         : MenuShow,
				             overflow       : 'hidden',
				             marginBottom   : '-2000px',
				             display        : 'block',
				             backgroundColor: '#FFF',
				boxShadow:'0px 10px 10px 0px #666'
			             })
			setMenuItemStyle({
				                 width: '100%'
			                 })
		} else {
			setMenuStyle({
				             height: 'auto'
			             })
			setMenuItemStyle({
				                 width: (
					                        100 / props.State.MenuList.length
				                        ) + '%'
			                 })
		}
	}, [
		          props,
		          MenuShow
	          ])
	//Header输出
	return (
		<Slide
			direction="down"
			in={true}
			mountOnEnter
			unmountOnExit
			style={{
				position: "relative",
				zIndex  : 9
			}}
		>
			<Grid
				container
				id={'Header'}
				className={'PageGridRoot'}
			>
				{/*页头Banner位置*/}
				<Banner
					State={props.State}
					setState={props.setState}
				/>
				<Grid container item style={{width:'100%'}}>
					<div style={{
						display       : "flex",
						width:'100%'
					}}>
						{/*页头菜单输出*/}
						{Conf().MobileDevice ||
						 <div
							 onClick={() => setMenuShow(MenuShow => MenuShow === '0' ? 'max-content' : '0')}
							 style={{
								 padding       : '0 10px',
								 justifyContent: "flex-end",
								 alignItems    : "center",
								 display       : "flex",
								 width         : '100%',
								 height        : '50px',
							 }}><Menu /></div>
						}
					</div>
				</Grid>
				<Grid
					container
					id={'HeaderMenu'}
					className={'PageGridMain'}
					style={MenuStyle}
					onClick={()=>{
						window.scrollTo(0,0)
						Conf().MobileDevice||setMenuShow('0')
					}}
				>
					{props.State.MenuList.map(
						item =>
							<Grid
								key={item.name}
								className={'MenuItem'}
								style={MenuItemStyle}
							>
								{/^https?:\/\//.test(item.to)
								 ? <a className={'MenuItemLink'}
								      href={item.to}>{item.name}</a>
								 : <NavLink
									 exact={item.to === '/'}
									 className={'MenuItemLink'}
									 to={item.to}
									 onClick={item.func}
								 >{item.name}</NavLink>}
							
							</Grid>
					)}
				</Grid>
			</Grid>
		</Slide>
	)
}
export default Header;
