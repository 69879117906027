/*
 * Copyright (C) 2020 qnnp. All rights reserved.
 * 项目名称：zs.jscj.edu.cn
 * 文件名称：SignUp.js
 * Date: 2020/9/17 上午9:13
 * Author:qnnp
 */
import React, {
	useEffect,
	useState
}           from "react";
import Conf from "../Common/Conf";
import {
	Button,
	Col,
	Form,
	Input,
	message,
	Radio,
	Row,
	Select,
	Typography,
	Upload
}           from "antd";
import POST from "../Common/POST";
import {useHistory} from "react-router-dom"
function SignUp(props) {
	let history = useHistory();
	const [Avatar, setAvatar] = useState()
	useEffect(() => {
		props.setState({
						   title      : '社会招生报名',
						   ShowSidebar: Conf().MobileDevice //判断移动设备
					   })
		setTimeout(() => window.scrollTo(0, 0))
	}, [])
	const [MainForm] = Form.useForm();
	function Submit(data) {
		console.log(data)
		// data.multipartFile = typeof data.multipartFile !== "undefined"
		//                      ? data.multipartFile.file
		//                      : message.error("请选择照片！")
		if (typeof data.multipartFile !== "undefined") {
			data.multipartFile = data.multipartFile.file
		} else {
			return message.error("请选择照片！")
		}
		if (!data.xxfs) {
			return message.error("请选择学习形式！")
		}
		if (!data.xm) {
			return message.error("请填写姓名！")
		}
		if (!data.sfzh) {
			return message.error("请填写身份证号！")
		}
		if (!data.authCode) {
			return message.error("请输入验证码！")
		}
		if (!data.zy) {
			return message.error("请选择专业！")
		}
		// data.file = data.multipartFile.file
		// console.log(data)
		POST("/student/sociologyEnrollment", data, {}, false)
			.then(res => {
				if (res.status === 200) {
					message.success("报名成功！\n请等待老师联系你。", 15)
					setAvatar("")
					MainForm.resetFields()
				} else {
					res.message !== null && message.error(res.message)
				}
			})
	}
	const [Phone, setPhone]           = useState("")
	const [CodeStatus, setCodeStatus] = useState(0)
	useEffect(() => {
		if (CodeStatus > 0) {
			setTimeout(() => {
				setCodeStatus(CodeStatus - 1)
			}, 1000)
		}
	}, [CodeStatus])
	return (
		<Row justify={"center"}
			 style={{
				 width : "100%",
				 margin: "30px 20px"
			 }}>
			<Col span={24} style={{
				maxWidth: "400px",
			}}>
				<Form
					form={MainForm}
					onFinish={Submit}
					layout={"vertical"}
					// labelCol={{span: 7}}
					// wrapperCol={{span: 15}}
					initialValues={{
						// xxfs: "全日制在校",
						zy: "电子商务",
					}}
				>
					<Typography.Title
						level={3}
						style={{
							textAlign   : "center",
							marginBottom: "20px"
						}}
					>
						2020年面向社会人员招生
						<br />
						报名材料上传
					</Typography.Title>
					<Form.Item
						style={{
							textAlign  : "center",
							marginRight: "-8px"
						}}
						name={"multipartFile"}
						// children={<Input size={"large"} type={"file"} />}
						children={
							<Upload
								beforeUpload={file => {
									if (file.type !== "image/jpeg") {
										message.error("只能上传JPG格式的照片")
									}
									const ReadImg = new FileReader()
									ReadImg.readAsDataURL(file)
									ReadImg.onload = () => {
										setAvatar(ReadImg.result)
									}
									return false;
								}}
								accept={"image/jpeg"}
								showUploadList={false}
								style={{width: "100%"}}
							>
								<div
									style={{
										maxWidth       : "200px",
										minHeight      : "30px",
										border         : "dashed 2px #EEE",
										width          : "100%",
										backgroundColor: "#fafafa",
										cursor         : "pointer"
									}}
								>
									{
										Avatar
										? <div>
											<img
												src={Avatar}
												alt={"Avatar"}
												style={{
													width: "100%"
												}}
											/>
											<br />
											<div style={{textAlign: "center"}}>
												重新上传
											</div>
										</div>
										: <div style={{padding: "10px 20px"}}>
											请点击上传蓝底照片
											<br />
											尺寸不大于480&times;620，且高不得小于宽，小于40k大小
										</div>
									}
								</div>
							</Upload>
						}
					/>
					<Form.Item
						label={"姓名"}
						name={"xm"}
						children={<Input placeholder={"请输入姓名"} size={"large"} type={"text"} />}
					/>
					<Form.Item
						label={"身份证号"}
						name={"sfzh"}
						children={<Input placeholder={"请输入身份证号码"} size={"large"} type={"text"} />}
					/>
					<Form.Item
						label={"报考专业"}
						name={"zy"}
						children={
							<Select>
								<Select.Option value={"电子商务"}>电子商务</Select.Option>
								<Select.Option value={"城市轨道交通运营管理"}>城市轨道交通运营管理</Select.Option>
								<Select.Option value={"幼儿发展与健康管理"}>幼儿发展与健康管理</Select.Option>
								<Select.Option value={"计算机应用技术"}>计算机应用技术</Select.Option>
								<Select.Option value={"粮食工程技术"}>粮食工程技术</Select.Option>
							</Select>
						}
					/>
					<Form.Item
						label={"选择学习形式"}
						name={"xxfs"}
						children={
							<Radio.Group>
								<Radio value={"全日制在校"}>全日制在校</Radio>
								<Radio value={"线上线下结合"}>线上线下结合（集中授课占40%）</Radio>
							</Radio.Group>
						}
					/>
					{/*
					 全日制在校
					 线上线下结合
					 */}
					<Row justify={"space-between"}>
						<Col span={24}>
							<Form.Item
								label={"手机号"}
								name={"sjh"}
								children={
									<Input
										placeholder={"请输入联系手机号码"}
										size={"large"}
										type={"text"}
										onChange={e => {
											setPhone(e.target.value)
										}}
									/>
								}
							/>
						</Col>
						<Col span={11}>
							<Form.Item
								name={"authCode"}
								children={<Input placeholder={"验证码"} size={"large"} type={"text"} />}
							/>
						</Col>
						<Col span={11}>
							<Button
								block
								type={"text"}
								onClick={() => {
									POST("/sms/sms", {phone: Phone}, {}, false)
										.then(res => {
											if (res.status === 200) {
												setCodeStatus(60)
											} else {
												message.info(res.message)
											}
										})
								}}
								disabled={CodeStatus}
								size={"large"}
							>
								获取验证码{CodeStatus === 0 || `(${CodeStatus})`}
							</Button>
						</Col>
						<Col style={{marginTop: "20px"}} />
						<Col span={11}>
							
							<Button
								type={"text"}
								block
								size={"large"}
								onClick={() => {
									MainForm.resetFields()
								}}
							>重新填</Button>
						</Col>
						<Col span={11}>
							
							<Button
								type={"primary"}
								block
								size={"large"}
								htmlType={"submit"}
							>提交信息</Button>
						</Col>
					</Row>
					<Row style={{marginTop:"20px"}}>
						<Button block type={"dashed"} onClick={()=>history.push('/SignUpQuery')}>打印准考证</Button>
					</Row>
				</Form>
			</Col>
		</Row>
	)
}
export default SignUp;
