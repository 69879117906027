/*
 * Copyright (C) 2020 qnnp. All rights reserved.
 * 项目名称：zs.jscj.edu.cn
 * 文件名称：AboutUs.js
 * Date: 2020/8/19 下午12:07
 * Author:qnnp
 */
import React, {
	useEffect,
	useState
}           from "react";
import Conf from "../Common/Conf";
import POST               from "../Common/POST";

function AboutUs(props) {
	// let [SchoolInfo,setSchoolInfo]=useState({})
	useEffect(() => {
		props.setState(
			{
				title      : '联系我们',
				ShowSidebar: Conf().MobileDevice
			}
		)
		// POST('/other/otherList',{type:16})
		// 	.then(data=>{
		// 		setSchoolInfo(data.data[0])
		// 	})
	}, [])
	return (
		<div>
			<h2 style={{
				width:'100%',
				textAlign:'center'
			}}>关于我们</h2>
			<div
				style={{
					padding:'15px'
				}}
				dangerouslySetInnerHTML={{__html:props.State.SchoolInfo.schoolIntroduce}}
			/>
		</div>
		
	)
}
export default AboutUs;
