/*
 * Copyright (C) 2020 qnnp. All rights reserved.
 * 项目名称：zs.jscj.edu.cn
 * 文件名称：App.js
 * Date: 2020/8/18 上午10:42
 * Author:qnnp
 */
import {Grid}                          from "@material-ui/core";
import axios                           from "axios";
import React, {useEffect, useState}   from 'react';
import './App.css';
import {BrowserRouter, Route, Switch} from "react-router-dom";
import Conf                            from "./Common/Conf";
import Footer                          from "./Common/Footer";
import './Common/Style/Main.css'
import Header                          from "./Common/Header";
import POST                            from "./Common/POST";
import Sidebar                         from "./Common/Sidebar";
import AboutUs                         from "./Page/AboutUs";
import Guestbook                       from "./Page/Guestbook";
import Home                            from "./Page/Home";
import DKZS2021                        from './Page/jianzhang/DKZS2021'
import Y2021                           from "./Page/jianzhang/Y2021";
import NotFound                        from "./Page/NotFound";
import "babel-polyfill";
import Post                            from "./Page/Post";
import PostList                        from "./Page/PostList";
import Query                           from "./Page/Query";
import SignUp                          from "./Page/SignUp";
import SignUpCat                       from "./Page/SignUpCat";
import SignUpQuery                     from "./Page/SignUpQuery";
import TZQuery                         from "./Page/TZQuery";
import TZZkz                           from "./Page/TZZkz";

axios.defaults.baseURL = Conf().API.replace(/^.*?(\/\/[^/]+).*$/,'$1')
// axios.defaults.baseURL = 'http://127.0.0.1:8989'
axios.defaults.withCredentials = true
function App() {
  //公用全局状态
  let [PublicState, setPublicState] =
        useState({
          SchoolName: '江苏财经职业技术学院',
          //页面标题
          title: '招生信息网',
          //菜单列表
          MenuList: Conf().MenuList,
          //通用样式参素
          Style      : {
            MainColor: '#1F6DCD',
            MainWidth: '1200px',
          },
          ShowSidebar: true,
          Fullwidth  : false,
          SchoolInfo : {},
        })

  function setState(newData) {
    setPublicState(JSON.parse(JSON.stringify(Object.assign(PublicState, newData))))
  }

  //更新页面标题
  useEffect(() => {
    document.title = PublicState.title + ' - ' + PublicState.SchoolName
  }, [PublicState.title])
  useEffect(() => {
    // if (window.location.protocol === "http:") {
    // 	if (window.location.host=== "zs.jscj.edu.cn") {
    // 		message.info("正在跳转到安全网站！",8)
    // 		window.location.href = "https://" + window.location.host + window.location.pathname + window.location.search + window.location.hash
    // 	}
    // }
    POST('/other/otherList', {type: 16})
      .then(data => {
        setState({
          SchoolInfo: data
            .data[0],
        })
      })
  }, [])
  //APP输出
  return (
    <BrowserRouter>
      <Grid container className={'PageGridRoot'}>
        <Header State={PublicState} setState={setState} />
        <Grid
          container
          item
          className={PublicState.ShowSidebar ? 'PageGridMain' : 'PageGridRoot'}
          id={'MainContent'}
        >
          {PublicState.ShowSidebar && <Sidebar State={PublicState} setState={setState} xs={3} />}
          <Grid container item xs={PublicState.ShowSidebar ? 9 : 12}>
            <Switch>
              <Route
                path={'/'}
                exact
                children={<Home State={PublicState} setState={setState} />}
              />
              <Route
                path={'/Home'}
                exact
                children={<Home State={PublicState} setState={setState} />}
              />
              <Route
                path={'/Query'}
                exact
                children={<Query State={PublicState} setState={setState} />}
              />
              <Route
                path={'/Query/:Page'}
                exact
                children={<Query State={PublicState} setState={setState} />}
              />
              <Route
                path={'/Guestbook'}
                exact
                children={<Guestbook State={PublicState} setState={setState} />}
              />
              <Route
                path={'/Guestbook/:Page'}
                exact
                children={<Guestbook State={PublicState} setState={setState} />}
              />
              <Route
                path={'/AboutUs'}
                exact
                children={<AboutUs State={PublicState} setState={setState} />}
              />
              <Route
                path={'/Post'}
                exact
                children={<Post State={PublicState} setState={setState} />}
              />
              <Route
                path={'/Post/:PostType/:PostID'}
                exact
                children={<Post State={PublicState} setState={setState} />}
              />
              <Route
                path={'/PostList'}
                exact
                children={<PostList State={PublicState} setState={setState} />}
              />
              <Route
                path={'/PostList/:PostType'}
                exact
                children={<PostList State={PublicState} setState={setState} />}
              />
              <Route
                path={'/PostList/:PostType/:Page'}
                exact
                children={<PostList State={PublicState} setState={setState} />}
              />
              <Route
                path={'/SignUp'}
                exact
                children={<SignUp State={PublicState} setState={setState} />}
              />
              <Route
                path={'/SignUpQuery'}
                exact
                children={<SignUpQuery State={PublicState} setState={setState} />}
              />
              <Route
                path={'/SignUpCat'}
                exact
                children={<SignUpCat State={PublicState} setState={setState} />}
              />
              <Route
                path={'/Year/2021'}
                exact
                children={<Y2021 State={PublicState} setState={setState} />}
              />
              <Route
                path={'/Year/2021/DKZS'}
                exact
                children={<DKZS2021 State={PublicState} setState={setState} />}
              />
              <Route
                path={'/TZZkz'}
                exact
                children={<TZZkz State={PublicState} setState={setState} />}
              />
              <Route
                path={'/TZQuery'}
                exact
                children={<TZQuery State={PublicState} setState={setState} />}
              />
              <Route component={NotFound} />
            </Switch>
          </Grid>
        </Grid>
        <Footer State={PublicState} setState={setState} />
      </Grid>
    </BrowserRouter>
  );
}

export default App;
