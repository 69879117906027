/*
 * Copyright (C) 2020 qnnp. All rights reserved.
 * 项目名称：zs.jscj.edu.cn
 * 文件名称：Sidebar.js
 * Date: 2020/8/18 下午4:27
 * Author:qnnp
 */

import React, {
	useEffect,
	useState
}             from "react";
import {Grid} from "@material-ui/core";
import POST               from "./POST";

function Sidebar(props){
	// let [SchoolInfo,setSchoolInfo]=useState({})
	useEffect(()=>{
		// POST('/other/otherList',{type:16})
		// 	.then(data=>{
		// 		setSchoolInfo(data.data[0])
		// 	})
	},[])
	return(
		<Grid container item xs={props.xs} style={{
			borderRight:'1px #f6f6f6 solid'
		}}>
				<div style={{
					padding:'12px',
					width:'100%'
				}}>
					<h5 style={{
						width:'100%',
						borderBottom:'1px #f0f0f0 solid'
					}}>联系我们</h5>
					<ul style={{
						margin:0,
						padding:0,
						fontSize:'14px',
						lineHeight:'200%'
					}}>
						<li style={{listStyle:"none"}}>
							{props.State.SchoolInfo.schoolName}
						</li>
						<li style={{listStyle:"none"}}>
							联系人：{props.State.SchoolInfo.schoolContacter}
						</li>
						<li style={{listStyle:"none"}}>
							电&emsp;话：<span dangerouslySetInnerHTML={{__html: props.State.SchoolInfo.schoolTel}}/>
						</li>
						<li style={{listStyle:"none"}}>
							传&emsp;真：{props.State.SchoolInfo.schoolFax}
						</li>
						<li style={{listStyle:"none"}}>
							地&emsp;址：{props.State.SchoolInfo.schoolAddress}
						</li>
						<li style={{listStyle:"none"}}>
							Q &emsp;Q ：{props.State.SchoolInfo.schoolQq}
						</li>
						<li style={{listStyle:"none"}}>
							E-mail&nbsp;：{props.State.SchoolInfo.schoolEmail}
						</li>
					</ul>
				</div>
		</Grid>
	)
}
export default Sidebar;
