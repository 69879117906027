/*
 * Copyright (C) 2021 qnnp. All rights reserved.
 * 项目名称：zs.jscj.edu.cn
 * 文件名称：DKZS2021.js
 * Date: 2021/2/25 下午4:15
 * Author:qnnp
 */
import React, {useEffect} from "react";
import Conf               from "./../../Common/Conf";
import {
	Carousel,
	Image,
	Table
}                         from "antd";
import './style.css';
const TheImage       = ({id, c, t = 'jpg'}) => {
	const images = [];
	for (let j = 1; j <= c; j++) {
		images[j] =
			<div>
				<div
					style={{
						position     : 'relative',
						width        : '100%',
						paddingBottom: '50%',
						height       : 0
					}}
				>
					<div
						style={{
							position : 'absolute',
							top      : '50%',
							left     : '50%',
							transform: 'translate(-50%, -50%)',
							width    : '100%',
							maxWidth : '100%',
							padding  : '20px',
						}}
					>
						<Image
							src={`${Conf().PUBLIC}/images/2021/${id}/${id}-${j}.${t}`}
							width={'100%'}
							alt={id + 'text'}
						/>
					</div>
				</div>
			</div>
	}
	return (
		<Image.PreviewGroup>
			<Carousel
				autoplay
				touchMove
				slidesToShow={2}
				slidesToScroll={1}
				className={'child-carousel'}
				customPaging={i => <div>{i + 1}</div>}
			>
				{images.map(img => img)}
			</Carousel>
		</Image.PreviewGroup>
	);
}
const TheTitle       = ({icon, cn, en, style = {}}) => {
	return (
		<div style={style}>
			<div
				style={{
					backgroundImage   : `url(${icon})`,
					backgroundPosition: 'center right',
					backgroundSize    : 'auto 90%',
					paddingRight      : '85px',
					width             : 'min-content',
					backgroundRepeat  : 'no-repeat',
					whiteSpace        : 'nowrap',
					margin            : '30px auto 48px',
					position          : 'relative',
				}}
			>
				<div
					style={{
						fontSize     : '36px',
						textAlign    : 'justify',
						textAlignLast: 'justify',
					}}
				>{cn}</div>
				<div
					style={{
						fontSize     : '14px',
						textAlign    : 'justify',
						textAlignLast: 'justify',
						letterSpacing: '3px',
					}}
				>{en}</div>
				<div
					style={{
						height         : '8px',
						width          : 'calc(100% - 85px)',
						backgroundColor: '#206CCD',
						position       : 'absolute',
						top            : '100%',
						marginTop      : '10px',
					}}
				/>
			</div>
		</div>
	);
}
const DownloadButton = ({text, url, target = '_top', style = {}}) => {
	return (
		<a
			style={{
				padding           : '10px 52px 10px 22px',
				fontSize          : '20px',
				textDecoration    : 'underline',
				fontWeight        : '400',
				color             : 'white',
				backgroundColor   : '#216CCC',
				borderRadius      : '10px',
				backgroundImage   : `url(${Conf().PUBLIC}/images/2021/icons/下载.png)`,
				backgroundPosition: 'calc(100% - 20px) center',
				backgroundSize    : 'auto 50%',
				backgroundRepeat  : 'no-repeat',
				cursor            : 'pointer',
				display           : 'inline-block',
				margin            : '20px auto',
				...style
			}}
			href={url}
			target={target}
		>
			<div>{text}</div>
		</a>
	)
}
const Y2021          = ({State, setState}) => {
	const mainColor = '#e1e5ef';
	const maxWidth  = 1500;
	useEffect(() => {
		setState({ShowSidebar: false});
		document.querySelector('meta[name="viewport"]')
				.setAttribute('content', 'width=1530, initial-scale=0.7')
		window.scrollTo(0, 0)
		return () => {
			window.scrollTo(0, 0)
			document.querySelector('meta[name="viewport"]')
					.setAttribute('content', 'width=device-width, initial-scale=1')
		}
	}, []);
	return (
		<div
			style={{
				width: "100%"
			}}
		>
			{/*Line1*/}
			<div
				style={{
					width             : "100%",
					height            : 698,
					display           : "flex",
					justifyContent    : "center",
					alignItems        : "center",
					backgroundImage   : 'url(' + Conf().PUBLIC + '/images/2021/bg1.JPG)',
					backgroundSize    : 'cover',
					backgroundPosition: 'bottom'
				}}
			>
				<div
					style={{
						width          : 738,
						height         : 158,
						backgroundColor: 'rgba(54,108,198,.55)',
						fontSize       : '48px',
						fontFamily     : '黑体',
						letterSpacing  : '10px',
						color          : 'white',
						textAlign      : 'center',
						lineHeight     : '158px',
					}}
				>
					2021提前招生专栏
				</div>
			</div>
			{/*Line2*/}
			<div
				style={{
					marginTop: '130px',
				}}
			>
				<div
					style={{
						width: '100%',
						// height         : '726px',
						maxWidth       : maxWidth,
						boxShadow      : '-6px 6px 43px rgba(54,108,198,0.3)',
						backgroundColor: mainColor,
						margin         : 'auto',
						display        : "flex",
						flexWrap       : 'nowrap',
						flexDirection  : 'row'
					}}
				>
					<div
						style={{
							width: 980 / maxWidth * 100 + '%',
						}}
					>
						<video
							style={{
								backgroundColor: 'black',
								padding        : 0,
								margin         : 0,
								display        : "block"
							}}
							controls
							// autoPlay
							src={'https://zs.jscj.edu.cn/safe-uploads/2021/zs-2021.mp4'}
							width={'100%'}
							height={'100%'}
							poster={`${Conf().PUBLIC}/images/2021/poster.png`}
						/>
					</div>
					<div
						style={{
							width: 100 - 980 / maxWidth * 100 + '%',
						}}
					>
						<div
							style={{
								display       : 'flex',
								alignItems    : 'center',
								justifyContent: 'center',
								flexDirection : 'column'
							}}
						>
							<TheTitle
								cn={'学校荣誉'}
								en={'School Honors'}
								icon={`${Conf().PUBLIC}/images/2021/icons/荣誉.png`}
							/>
							<div
								style={{
									display: 'inline-block'
								}}
							>
								<p>全国高职院校创新创业教育工作先进单位</p>
								<p>全国高职院校创新创业示范校50强</p>
								<p>江苏省文明单位</p>
								<p>江苏省教育工作先进单位</p>
								<p>江苏省园林式校园</p>
								<p>江苏省高校思想政治工作先进集体</p>
								<p>江苏省高校毕业生就业工作先进集体</p>
								<p>江苏省大学生创业教育示范校</p>
								<p>江苏省智慧校园示范校</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/*Line3*/}
			<div
				style={{
					margin         : '130px 0 0',
					backgroundColor: '#ddd',
					display        : 'block',
					paddingBottom  : '60px',
					position       : 'relative',
				}}
			>
				<div
					style={{
						position            : 'absolute',
						top                 : 0,
						left                : 0,
						right               : 0,
						bottom              : 0,
						backgroundImage     : `url(${Conf().PUBLIC}/images/2021/1/1-1.jpg)`,
						backgroundPosition  : 'scroll',
						backgroundSize      : 'cover',
						zIndex              : 1,
						opacity             : 0.1,
						backgroundAttachment: 'fixed'
					}}
				/>
				<div
					style={{
						position: 'relative',
						zIndex  : 2
					}}
				>
					
					<div
						style={{
							maxWidth      : maxWidth,
							width         : '100%',
							margin        : 'auto',
							display       : 'flex',
							flexWrap      : 'nowrap',
							justifyContent: 'space-between',
							alignItems    : 'center'
						}}
					>
						<div
							style={{
								width     : 'min-content',
								display   : 'inline-block',
								marginLeft: '60px'
							}}
						>
							<TheTitle
								cn={'招生计划'}
								en={'Enrollment plan'}
								icon={`${Conf().PUBLIC}/images/2021/icons/学生.png`}
							/>
						</div>
						<div
							style={{}}
						>
							<DownloadButton
								text={'2021年提前招生计划'}
								target={'_blank'}
								url={'https://zs.jscj.edu.cn/safe-uploads/2021/2021年提前招生计划.xlsx'}
							/>
						</div>
					</div>
					<div
						style={{
							maxWidth      : maxWidth,
							width         : '100%',
							margin        : 'auto',
							display       : 'flex',
							flexWrap      : 'nowrap',
							justifyContent: 'space-between',
							alignItems    : 'center'
						}}
					>
						<Table
							style={{width: '100%'}}
							dataSource={[
								{"name": "会计", "category": "物理或历史", "plan_no": 200, "charge": 4700, "remark": ""},
								{"name": "统计与会计核算", "category": "物理或历史", "plan_no": 100, "charge": 4700, "remark": ""},
								{"name": "金融管理", "category": "物理或历史", "plan_no": 80, "charge": 4700, "remark": ""},
								{"name": "财务管理", "category": "物理或历史", "plan_no": 60, "charge": 4700, "remark": ""},
								{"name": "工商企业管理", "category": "物理或历史", "plan_no": 40, "charge": 4700, "remark": ""},
								{"name": "市场营销", "category": "物理或历史", "plan_no": 40, "charge": 4700, "remark": ""},
								{"name": "电子商务", "category": "物理或历史", "plan_no": 60, "charge": 4700, "remark": ""},
								{"name": "工程造价", "category": "物理或历史", "plan_no": 40, "charge": 4700, "remark": ""},
								{"name": "法律事务", "category": "物理或历史", "plan_no": 80, "charge": 4700, "remark": ""},
								{"name": "司法助理", "category": "物理或历史", "plan_no": 40, "charge": 4700, "remark": ""},
								{"name": "幼儿发展与健康管理", "category": "物理或历史", "plan_no": 50, "charge": 4700, "remark": ""},
								{
									"name"    : "视觉传播设计与制作",
									"category": "美术类（物理或历史）",
									"plan_no" : 40,
									"charge"  : 6800,
									"remark"  : ""
								},
								{"name": "数字媒体应用技术", "category": "物理或历史", "plan_no": 40, "charge": 5300, "remark": ""},
								{"name": "汽车检测与维修技术", "category": "物理或历史", "plan_no": 80, "charge": 5300, "remark": ""},
								{"name": "计算机应用技术", "category": "物理或历史", "plan_no": 100, "charge": 5300, "remark": ""},
								{"name": "计算机网络技术", "category": "物理或历史", "plan_no": 40, "charge": 5300, "remark": ""},
								{"name": "大数据技术与应用", "category": "物理或历史", "plan_no": 60, "charge": 5300, "remark": ""},
								{"name": "机电一体化技术", "category": "物理或历史", "plan_no": 80, "charge": 5300, "remark": ""},
								{"name": "电气自动化技术", "category": "物理或历史", "plan_no": 40, "charge": 5300, "remark": ""},
								{"name": "物联网应用技术", "category": "物理或历史", "plan_no": 40, "charge": 5300, "remark": ""},
								{"name": "物流管理", "category": "物理或历史", "plan_no": 80, "charge": 4700, "remark": ""},
								{"name": "旅游管理", "category": "物理或历史", "plan_no": 85, "charge": 4700, "remark": ""},
								{"name": "航空物流", "category": "物理或历史", "plan_no": 80, "charge": 5300, "remark": ""},
								{
									"name"    : "城市轨道交通运营管理",
									"category": "物理或历史",
									"plan_no" : 80,
									"charge"  : 5300,
									"remark"  : ""
								},
								{
									"name"    : "高速铁路客运乘务",
									"category": "物理或历史",
									"plan_no" : 80,
									"charge"  : 5300,
									"remark"  : "需专业面试"
								},
								{"name": "粮食工程技术", "category": "物理或历史", "plan_no": 40, "charge": 5300, "remark": ""},
								{"name": "药品质量与安全", "category": "物理或历史", "plan_no": 40, "charge": 5300, "remark": ""},
								{"name": "食品营养与检测", "category": "物理或历史", "plan_no": 40, "charge": 5300, "remark": ""},
								{
									"name"    : "财务管理（高职与普通本科分段培养项目）",
									"category": "物理或历史",
									"plan_no" : 40,
									"charge"  : 4700,
									"remark"  : ""
								}
							]}
							columns={[
								{
									title    : '专业名称',
									dataIndex: 'name',
									key      : 'name',
								},
								{
									title    : '首选科目',
									dataIndex: 'category',
									key      : 'category',
								},
								{
									title    : '计划招生',
									dataIndex: 'plan_no',
									key      : 'plan_no',
									render   : text => <span>{text} 人</span>,
									align    : 'right',
									// width    : 100
								},
								{
									title    : '收费标准',
									dataIndex: 'charge',
									key      : 'charge',
									render   : text => <span>¥ {text}</span>,
									align    : 'right',
									// width    : 100
								},
								{
									title    : '备注',
									dataIndex: 'remark',
									key      : 'remark',
								}
							]}
							pagination={false}
							// scroll={{y: 600}}
							// size={'small'}
							bordered
							onRow={(record, i) => {
								return {
									style: {
										fontSize       : '16px',
										fontWeight     : '400',
										backgroundColor: i % 2 !== 0 && '#ddd'
									}
								}
							}}
							onHeaderRow={columns => {
								return {
									style: {
										fontSize: '16px'
									}
								}
							}}
						/>
					</div>
				</div>
			</div>
			{/*Line4*/}
			<div
				style={{
					marginTop: '130px',
				}}
			>
				<div
					style={{
						width: '100%',
						// height         : '726px',
						maxWidth       : maxWidth,
						boxShadow      : '-6px 6px 43px rgba(54,108,198,0.17)',
						backgroundColor: mainColor,
						margin         : 'auto',
						display        : "flex",
						flexWrap       : 'nowrap',
						flexDirection  : 'row',
						position       : 'relative'
					}}
				>
					<div
						style={{
							width: `${(
										  maxWidth - 482
									  ) / maxWidth * 100}%`,
						}}
					>
						<TheTitle
							style={{
								width     : 'min-content',
								display   : 'inline-block',
								marginLeft: '60px',
							}}
							cn={'重点要求'}
							en={'Key requirements'}
							icon={`${Conf().PUBLIC}/images/2021/icons/重点.png`}
						/>
						<div
							style={{
								marginRight: '60px',
								marginLeft : '60px',
							}}
						>
							<p>招生对象：江苏省内已参加2021年普通高考报名，考生须参加普通高中学业水平语文、数学、外语、思想政治、历史、地理、物理、化学、生物、信息技术10门科目的合格性考试并取得成绩。</p>
							<p>对考生首选和再选科目均不做具体要求。</p>
							<p>报名方式：网上报名（3月9-11日登录www.jseea.cn报名)</p>
							<p>考核组织：报考高速铁路客运乘务的考生须参加学校组织的专业面试，面试合格方可录取在相应专业，面试时间及面试方案另行公布。报考其他专业的考生不再安排校测或面试。</p>
							<p>考核分值：以江苏省普通高中学业水平合格性考试结果为赋分依据，折合总分300分。（详细赋分办法见本校提前招生章程）</p>
							<p>日程安排：报名、考试等日程安排详见下表。若后期因疫情防控要求调整，以学校官网发布的时间为准。</p>
							<DownloadButton
								style={{
									position: 'absolute',
									bottom  : 20
								}}
								text={'2021年提前招生章程'}
								url={'https://zs.jscj.edu.cn/safe-uploads/2021/2021年提前招生章程.docx'}
							/>
						</div>
					</div>
					
					<div
						style={{
							width           : `${482 / maxWidth * 100}%`,
							height          : '720px',
							backgroundRepeat: 'no-repeat',
							backgroundImage : `url(${Conf().PUBLIC}/images/2021/bg3.png)`,
							backgroundSize  : 'cover'
						}}
					>
					</div>
				</div>
			</div>
			{/*Line5*/}
			<div
				style={{
					marginTop: '130px',
				}}
			>
				<div
					style={{
						width : '100%',
						margin: "auto",
						// display      : 'flex',
						// flexWrap     : 'wrap',
						maxWidth: '1500px',
						// alignItems   : 'center',
						// justifyItems : 'center'
					}}
				>
					<div
						style={{
							display       : 'flex',
							alignItems    : 'center',
							justifyContent: 'center',
						}}
					>
						<TheTitle
							style={{
								display: 'inline-block',
							}}
							cn={'校园财经'}
							en={'Campus Environment'}
							icon={`${Conf().PUBLIC}/images/2021/icons/校园.png`}
						/>
					</div>
					<Carousel
						autoplay={false}
						touchMove={false}
						slidesToShow={1}
						lazyLoad
						customPaging={(i) =>
							<div className={'button'}>{[
								'住在财院',
								'学在财院',
								'吃在财院'
							][i]}</div>
						}
						dots={{
							className: 'thumbs'
						}}
						className={'line5'}
						fade
					>
						<TheImage id={1} c={6} />
						<TheImage id={2} c={9} />
						<TheImage id={3} c={10} />
					</Carousel>
					<Carousel
						autoplay={false}
						touchMove={false}
						slidesToShow={1}
						lazyLoad
						customPaging={(i) =>
							<div className={'button'}>{[
								'嗨在财院',
								'成在财院',
								'校园文化'
							][i]}</div>
						}
						dots={{
							className: 'thumbs'
						}}
						className={'line5'}
						fade
					>
						<TheImage id={4} c={8} />
						<TheImage id={5} c={11} />
						<TheImage id={6} c={8} t={'png'} />
					</Carousel>
				</div>
			</div>
			{/*Line6*/}
			<div
				style={{
					marginTop      : '130px',
					backgroundColor: '#F4F6F9',
					position       : 'relative'
				}}
			>
				<div
					style={{
						backgroundAttachment: 'fixed',
						backgroundImage     : `url(/images/2021/1/1-5.jpg)`,
						backgroundSize      : 'cover',
						position            : 'absolute',
						zIndex              : 1,
						width               : '100%',
						height              : '100%',
						opacity             : .2,
					}}
				/>
				<div
					style={{
						position: 'relative',
						display : 'flex',
						flexWrap: 'nowrap',
						zIndex  : 2
					}}
				>
					{/*<div*/}
					{/*	style={{*/}
					{/*		width          : '50%',*/}
					{/*		height         : '658px',*/}
					{/*		backgroundSize : 'cover',*/}
					{/*		backgroundImage: `url(${Conf().PUBLIC}/images/2021/bg4.png)`*/}
					{/*	}}*/}
					{/*/>*/}
					<div
						style={{
							width         : '100%',
							display       : 'flex',
							justifyContent: 'center',
							alignItems    : 'center',
							flexDirection : 'column'
						}}
					>
						<TheTitle
							style={{
								display: 'inline-block'
							}}
							cn={'咨询方式'}
							en={'Advisory way'}
							icon={`${Conf().PUBLIC}/images/2021/icons/联系.png`}
						/>
						<div
							style={{
								margin : '20px auto 20px',
								display: 'inline-block',
							}}
						>
							<p>1．现场咨询：考生可到学校招生办公室进行现场咨询</p>
							<p>2．电话咨询：400-883-2686（免长途话费） 0517-83858686 83858666</p>
							<p>3．网上咨询：http://www.jscj.edu.cn http://zs.jscj.edu.cn</p>
							<p>4．微信咨询：微信号jscjzyjsxy</p>
							<p>5．Q Q咨询：297329171（郑老师）34958377（冯老师）</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
export default Y2021;
