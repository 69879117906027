/*
 * Copyright (C) 2020 qnnp. All rights reserved.
 * 项目名称：zs.jscj.edu.cn
 * 文件名称：Guestbook.js
 * Date: 2020/8/19 下午12:03
 * Author:qnnp
 */
import React, {
	useEffect,
	useState
}                  from "react";
import Conf        from "../Common/Conf";
import {Grid}         from "@material-ui/core";
import TextField      from "@material-ui/core/TextField";
import StateAssign    from "../Common/StateAssign";
import Button         from "@material-ui/core/Button";
import POST           from "../Common/POST";
import {
	useHistory,
	useParams
}                     from "react-router-dom";
import Pagination     from "@material-ui/lab/Pagination";
import ListItem       from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar         from "@material-ui/core/Avatar";
import List           from "@material-ui/core/List";
import ListItemText   from "@material-ui/core/ListItemText";
import Typography     from "@material-ui/core/Typography";
import './Style/Guestbook.css'
import moment         from "moment";
import "babel-polyfill";

function Guestbook(props) {
	let history                         = useHistory();
	let {Page}=useParams()
	let [CodeState, setCodeState]             = useState(0)
	let [MessageData, setMessageData]         = useState(
		{
			title   : '',
			content : '',
			name    : '',
			phone   : '',
			authCode: ''
		})
	let [MessageList, setMessageList]         = useState([])
	let [MessageListPage, setMessageListPage] = useState(1)
	let [ShowPhone, setShowPhone]             = useState(false)
	let [UpdateList,setUpdateList]=useState(0)
	useEffect(() => {
		props.setState({
			               title      : '在线咨询',
			               ShowSidebar: Conf().MobileDevice
		               })
	}, [])
	useEffect(() => {
		if (CodeState > 0) {
			setTimeout(() => {
				setCodeState(CodeState - 1)
			}, 1000)
		}
	}, [CodeState])
	useEffect(() => {
		POST('/other/otherListPaged', {
			type: 18,
			page: Page||1,
		})
			.then(data => {
				setMessageList(data.data.data)
				setMessageListPage(data.data.page)
			})
		window.scrollTo(0,0)
	}, [Page,UpdateList])
	return (
		<Grid container item xs={12} style={{justifyContent: "center"}}>
			{/*咨询框*/}
			<Grid container
			      item
			      xs={11}
			      style={{
				      justifyContent: "center",
				      marginTop     : '20px'
			      }}>
				<div style={{width: '100%'}}>
					<TextField
						multiline
						rows={4}
						size={"small"}
						variant={"outlined"}
						fullWidth
						label={'咨询信息'}
						required
						style={{marginBottom: '20px'}}
						onFocus={() => setShowPhone(true)}
						onChange={e => StateAssign(MessageData, {content: e.target.value})}
					/>
					<Grid container
					      item
					      style={{
						      justifyContent: "space-between",
						      marginBottom  : '20px'
					      }}>
						<TextField
							size={"small"}
							variant={"outlined"}
							label={'姓名'}
							placeholder={'请输入您的姓名'}
							required
							style={{width: 'calc( 100% - 110px )'}}
							onFocus={() => setShowPhone(true)}
							onChange={e => StateAssign(MessageData, {name: e.target.value})}
						/>
						<Button
							variant={"contained"}
							color={"primary"}
							size={"small"}
							style={{
								width         : '80px',
								justifyContent: "space-evenly"
							}}
							onClick={() => {
								POST('/other/feedback', MessageData)
									.then(data => {
										alert(data.message)
										setUpdateList(Math.random())
									})
							}}
						>提 交</Button>
					</Grid>
					<Grid container
					      item
					      style={{
						      justifyContent: "space-between",
						      marginBottom  : '20px',
						      transition    : 'all 0.5s',
						      overflow      : ShowPhone ? "visible" : "hidden",
						      height        : ShowPhone ? "auto" : "0"
					      }}>
						<TextField
							size={"small"}
							variant={"outlined"}
							label={'手机号'}
							placeholder={'请输入您的手机'}
							required
							style={{
								width       : Conf().MobileDevice ? 'calc( 50% - 10px )' : '100%',
								marginBottom: '20px'
							}}
							onChange={e => StateAssign(MessageData, {phone: e.target.value})}
						/>
						<TextField
							size={"small"}
							variant={"outlined"}
							label={'验证码'}
							placeholder={'请输入验证码'}
							required
							autoComplete={'false'}
							style={{
								width       : Conf().MobileDevice ? 'calc( 25% - 10px )' : 'calc( 100% - 110px )',
								marginBottom: '20px'
							}}
							onChange={e => StateAssign(MessageData, {authCode: e.target.value})}
						/>
						<Button
							onClick={() => {
								if (CodeState === 0) {
									POST('/sms/sms', {phone: MessageData.phone})
										.then(data => {
											if (data.status === 200) {
												setCodeState(60)
											}
											alert(data.message)
										})
								}
							}}
							disabled={!!CodeState}
							style={{
								marginBottom: '20px',
								width       : '100px'
							}}
						>获取验证码{CodeState > 0 ? '(' + CodeState + ')' : ''}</Button>
					</Grid>
					<TextField
						required
						type={'hidden'}
						defaultValue={MessageData.name}
					/>
				</div>
			</Grid>
			{/*咨询框End*/}
			<Grid container item xs={11}>
				<List>
					{MessageList.map(item=>
						<ListItem key={item.id} style={{alignItems:"start",marginBottom:'20px'}}>
							<ListItemAvatar>
								<Avatar
									style={{
										backgroundColor:'#1F6DCD'
									}}
								>{item.name?item.name[0]:'匿'}</Avatar>
							</ListItemAvatar>
							<ListItemText
								primary={
									<span>
										<span style={{color:"red"}}>
											问：
											<span style={{color:'#cecece'}}>
												{moment(item.addTime).format('YYYY年MM月DD日')}
											</span>
										</span>
										<br/>
										<span style={{whiteSpace:'break-spaces'}}>
											{item.content}
										</span>
									</span>}
								secondary={
									item.replycontent&&
									<span>
										<span style={{color:'#1F6DCD'}}>
											回：
											<span style={{color:'#cecece'}}>
												{moment(item.replyTime).format('YYYY年MM月DD日')}
											</span>
										</span>
											<br/>
										<span style={{whiteSpace:'break-spaces'}}>
											{item.replycontent}
										</span>
									</span>
								}
							/>
						</ListItem>
					)}
				</List>
				<Grid container item xs={12} style={{justifyContent:"center"}}>
					<Pagination
						shape={"rounded"}
						variant={"outlined"}
						color={"primary"}
						count={MessageListPage}
						page={(
							      Page || 1
						      ) * 1}
						defaultPage={(
							             Page || 1
						             ) * 1}
						onChange={(event, page) => {
							history.push('/Guestbook/' + page)
						}}
					/>
				</Grid>
			</Grid>
		</Grid>
	)
}
export default Guestbook;
