import {Box, Tab, Tabs}             from "@material-ui/core";
import Typography                   from "@material-ui/core/Typography";
import PropTypes                    from 'prop-types';
import React, {useEffect, useState} from "react";
import ReactDOM                     from "react-dom";
import SwipeableViews               from 'react-swipeable-views';
import Conf                         from "./Conf";
import './Style/HomePI.css'

function TabPanel(props) {
  const {children, value, index, ...other} = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {/*{value === index && (*/}
      <Box p={3}>
        <Typography>{children}</Typography>
      </Box>
      {/*)}*/}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index   : PropTypes.any.isRequired,
  value   : PropTypes.any.isRequired,
};

const Slider = ({college}) => {
  const [index, setIndex] = useState(0)
  const [viewImage, setViewImage] = useState('')
  const pages = college.pro.length - (
    Conf().MobileDevice ? 4 : 1
  )
  useEffect(() => {
    let root = document.getElementById('_homeSliderViewImage')
    if (viewImage) {
      if (!root) {
        root = document.createElement('div')
        root.id = '_homeSliderViewImage'
        document.body.appendChild(root)
      }
      const el = <div>
        <div
          style={{
            position       : 'fixed',
            top            : '50%',
            left           : '50%',
            transform      : 'translate(-50%,-50%)',
            width          : '100vw',
            height         : '100vh',
            zIndex         : 9999,
            backgroundColor: 'rgba(0,0,0,0.4)',
          }}
          onClick={() => {
            setViewImage('')
          }}
        />
        <div
          style={{
            position       : 'fixed',
            top            : 'calc(2vh - 12px)',
            right          : 'calc(5vw - 12px)',
            width          : '48px',
            height         : '48px',
            zIndex         : 10001,
            backgroundColor: 'rgba(255,0,0,0.7)',
            borderRadius   : '50%',
            cursor         : 'pointer',
          }}
          onClick={() => {
            setViewImage('')
          }}
        >
          <div
            style={{
              position  : 'absolute',
              top       : '50%',
              left      : '50%',
              transform : 'translate(-50%,-50%)',
              fontSize  : '48px',
              fontFamily: 'monospace',
              fontWeight: '400',
              color     : 'white',
            }}
          >×
          </div>
        </div>
        <div
          style={{
            position       : 'fixed',
            top            : '50%',
            left           : '50%',
            transform      : 'translate(-50%,-50%)',
            width          : '90vw',
            height         : '96vh',
            zIndex         : 10000,
            backgroundColor: '#fff',
            overflow       : 'auto',
          }}
        >
          <img
            src={viewImage}
            border={0}
            style={{
              width : '100%',
              border: 'none',
            }}
          />
        </div>
      </div>
      document.body.style.overflow = 'hidden'
      ReactDOM.render(el, root)
    } else if (root) {
      root.innerHTML = ''
      document.body.style.overflow = 'auto'
    }
    return () => {
      root && document.body.removeChild(root)
      document.body.style.overflow = 'auto'
    }
  }, [viewImage]);
  return <div
    className={'HomeSlider'}
    style={{
      position: 'relative',
    }}
  >
    {[null, null].map(
      (e, i) =>
        <div
          key={i}
          className={'nav'}
          style={{
            position       : 'absolute',
            top            : `calc(50% - 24px)`,
            right          : i ? `-${Conf().MobileDevice ? 24 : 12}px` : null,
            left           : i ? null : `-${Conf().MobileDevice ? 24 : 12}px`,
            height         : `${Conf().MobileDevice ? 48 : 36}px`,
            width          : `${Conf().MobileDevice ? 48 : 36}px`,
            backgroundColor: '#fff',
            borderRadius   : '50%',
            border         : '1px solid #e6e6e6',
            textAlign      : 'center',
            fontSize       : `${Conf().MobileDevice ? 36 : 24}px`,
            cursor         : 'pointer',
            userSelect     : 'none',
            zIndex         : 1,
            fontFamily     : 'monospace',
            color          : !i ? index > 0 ? '#666' : '#ccc' : pages > 0 && index < pages ? '#666' : '#ccc',
          }}
          onClick={() => {
            if (!i) {
              index > 0 && setIndex(index - 1)
            } else {
              pages > 0 && index < pages && setIndex(index + 1)
            }
          }}
        >
          <div
            style={{
              position : 'absolute',
              top      : '50%',
              left     : '50%',
              transform: 'translate(-50%, -50%)',
            }}
          >{!i ? '«' : '»'}</div>
        </div>,
    )}
    <div
      style={{
        width   : '100%',
        overflow: 'hidden',
      }}
    >
      <div
        style={{
          display   : 'flex',
          transition: 'all 0.2s',
          transform : `translateX(-${index * (
            Conf().MobileDevice ? 25 : 100
          )}%)`,
        }}
      >
        {college.pro.map(
          (pro,i) => (
            <div
              key={i}
              style={{
                width             : `calc(${Conf().MobileDevice ? 25 : 100}% - 16px)`,
                margin            : '0 8px',
                height            : 0,
                paddingBottom     : `calc((${Conf().MobileDevice ? 25 : 100}% - 16px) * 0.6)`,
                minWidth          : `calc(${Conf().MobileDevice ? 25 : 100}% - 16px)`,
                border            : '1px solid #e6e6e6',
                position          : 'relative',
                backgroundImage   : `url(http://zs.jscj.edu.cn/safe-uploads/专业介绍/${college.shortName}-${pro.name}-s.jpg)`,
                backgroundSize    : 'cover',
                backgroundPosition: 'top center',
                backgroundRepeat  : 'no-repeat',
                cursor            : 'pointer',
              }}
              onClick={() => {
                setViewImage(`http://zs.jscj.edu.cn/safe-uploads/专业介绍/${college.shortName}-${pro.name}.jpg`)
              }}
            >
              <div
                style={{
                  position       : 'absolute',
                  bottom         : '0',
                  right          : 0,
                  left           : 0,
                  padding        : '6px',
                  textAlign      : 'center',
                  backgroundColor: '#fff',
                  boxShadow      : '10px 0 10px #e6e6e6',
                }}
              >{pro.name}</div>
            </div>
          ),
        )}
      </div>
    </div>
  </div>
}

const HomePI = () => {
  const [index, setIndex] = useState(0)
  const [list] = useState(
    [
      {
        college  : "会计学院",
        shortName: '会计学院',
        pro      : [
          {name: '大数据与会计'},
          {name: '会计信息管理'},
          {name: '统计与会计核算'},
          {name: '大数据与审计'},
          {name: '财税大数据应用'},
        ],
      },
      {
        college  : "经济管理学院",
        shortName: '经管学院',
        pro      : [
          {name: '工商企业管理'},
          {name: '市场营销'},
          {name: '电子商务'},
          {name: '工程造价'},
        ],
      },
      {
        college  : "物流与交通旅游学院",
        shortName: '物流学院',
        pro      : [
          {name: '现代物流管理'},
          {name: '航空物流'},
          {name: '高速铁路客运服务'},
          {name: '城市轨道交通运营管理'},
          {name: '旅游管理'},
        ],
      },
      {
        college  : "金融学院",
        shortName: '金融学院',
        pro      : [
          {name: '金融服务与管理'},
          {name: '金融科技应用'},
          {name: '大数据与财务管理'},
        ],
      },
      {
        college  : "法律与人文艺术学院",
        shortName: '法文学院',
        pro      : [
          {name: '法律事务'},
          {name: '婴幼儿托育服务与管理'},
          {name: '数字媒体技术'},
          {name: '视觉传达设计'},
        ],
      },
      {
        college  : "智能工程技术学院",
        shortName: '智能学院',
        pro      : [
          {name: '机电一体化技术'},
          {name: '电气自动化技术'},
          {name: '汽车制造与试验技术'},
          {name: '物联网应用技术'},
          {name: '计算机应用技术'},
          {name: '计算机网络技术'},
          {name: '大数据技术'},
        ],
      },
      {
        college  : "粮食与食品药品学院",
        shortName: '粮药学院',
        pro      : [
          {name: '粮食工程技术与管理'},
          {name: '药品质量与安全'},
          {name: '食品药品监督管理'},
        ],
      },
      {
        college  : '商务英语学院',
        shortName: '商英学院',
        pro      : [
          {name: '商务英语'},
        ],
      },
    ])
  return <div className={'PostListBox ThemeBig'}>
    <h5 className={'PostListBoxTitle'}>
      <div
        style={{
          display   : 'flex',
          gap       : '8px',
          whiteSpace: 'nowrap',
        }}
      >
        <span>专业介绍</span>
        <Tabs
          value={index}
          onChange={(e, index) => setIndex(index)}
          scrollButtons={'on'}
          variant="scrollable"
        >
          {list.map(item => <Tab key={item.college} label={item.college} />)}
        </Tabs>
      </div>
    </h5>
    <SwipeableViews
      index={index}
      onChangeIndex={setIndex}
      disabled
    >
      {list.map((college, _index) => (
        <TabPanel value={index} index={_index} key={_index}>
          <Slider college={college} />
        </TabPanel>
      ))}
    </SwipeableViews>
  </div>
}
export default HomePI
