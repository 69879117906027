/*
 * Copyright (C) 2020 qnnp. All rights reserved.
 * 项目名称：zs.jscj.edu.cn
 * 文件名称：Post.js
 * Date: 2020/8/18 上午10:42
 * Author:qnnp
 */
import {Grid}                         from "@material-ui/core";
import Fade                           from "@material-ui/core/Fade";
import List                           from "@material-ui/core/List";
import ListItem                       from "@material-ui/core/ListItem";
import ListItemText                   from "@material-ui/core/ListItemText";
import GetAppIcon                     from '@material-ui/icons/GetApp';
import VisibilityIcon                 from '@material-ui/icons/Visibility';
import Pagination                     from "@material-ui/lab/Pagination";
import moment                         from "moment";
import React, {useEffect, useState}  from "react";
import {Link, useHistory, useParams} from "react-router-dom";
import Conf                           from "../Common/Conf";
import POST                           from "../Common/POST";
import "babel-polyfill";

function PostList(props) {
  let history = useHistory();
  let {PostType, Page} = useParams()
  let [Fadein, setFadein] = useState(false)
  let [PostListData, setPostListData] = useState([])
  let [PostListPage, setPostListPage] = useState(1)
  useEffect(() => {
    props.setState({
                     title      : PostType,
                     ShowSidebar: Conf().MobileDevice,
                   })
    if (PostType === '下载中心') {
      POST('/article/downloadCenter', {
        type: Conf().PostType[PostType],
        page: Page || 1,
      })
        .then(data => {
          if (data.status === 200) {
            setPostListData(data.data.data)
            setPostListPage(data.data.page)
          }
          setFadein(true)
        })
    } else {
      POST('/article/findList', {
        type: Conf().PostType[PostType],
        page: Page || 1,
      })
        .then(data => {
          if (data.status === 200) {
            setPostListData(data.data.data)
            setPostListPage(data.data.page)
          }
          setFadein(true)
        })
    }
    window.scrollTo(0, 0)
  }, [
              PostType,
              Page,
            ])
  //Post输出
  return (
    <Fade in={Fadein}>
      <Grid container item className={'PageGridRoot'}>
        <Grid container item className={'PageGridMain'}>
          <List className={'fullWidth'}>
            {PostListData.map(
              item =>
                <ListItem key={item.id || item.fileId}>
                  <Link
                    to={'/Post/' + Conf().PostType[PostType] + '/' + item.id || item.fileId}
                    style={{
                      width: '100%',
                    }}
                    onClick={e => {
                      if (!!item.isUrl) {
                        window.open(item.url)
                        e.preventDefault()
                      } else if (PostType === '下载中心') {
                        window.open(item.filePath)
                        POST('/article/downloadCenterClick', {
                          type: 6,
                          id  : item.fileId,
                        })
                          .then()
                        e.preventDefault()
                      }
                    }}
                  >
                    <ListItemText
                      primary={
                        <div>
                          {PostType === '下载中心'
                           ?
                           <span>{item.fileTitle}</span>
                           : item.title}
                          <span
                            style={{
                              float: "right",
                              color: '#999',
                            }}
                          >
														{/*{(new Date(item.addTime).toLocaleDateString())}*/}
                            {PostType === '下载中心'
                             ? <span><GetAppIcon
                                style={{
                                  fontSize    : '16px',
                                  marginBottom: '-3px',
                                  marginRight : '3px',
                                }}
                              />{item.fileTimes || 0}次</span>
                             :
                             <span><VisibilityIcon
                               style={{
                                 fontSize    : '16px',
                                 marginBottom: '-3px',
                                 marginRight : '3px',
                               }}
                             />{item.viewtimes}次</span>}
                            &emsp;{moment(item.addTime)
                            .format('YYYY年MM月DD日')}
													</span>
                        </div>
                      }
                    />
                  </Link>
                </ListItem>,
            )}
          </List>
          <Grid container item xs={12} style={{justifyContent: "center"}}>
            <Pagination
              shape={"rounded"}
              variant={"outlined"}
              color={"primary"}
              count={PostListPage}
              page={(
                      Page || 1
                    ) * 1}
              defaultPage={(
                             Page || 1
                           ) * 1}
              onChange={(event, page) => {
                history.push('/PostList/' + PostType + '/' + page)
                console.log('/PostList/' + PostType + '/' + page)
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Fade>
  )
}

export default PostList;
