/*
 * Copyright (C) 2020 qnnp. All rights reserved.
 * 项目名称：jsjx-single-page-xxyey
 * 文件名称：POST.js
 * Date: 2020/8/27 下午3:31
 * Author:qnnp
 */
import React     from 'react';
import Conf      from "./Conf";
import {message} from 'antd';
require('es6-promise')
	.polyfill();
require('isomorphic-fetch');
/**
 * @param {string} url
 * @param {{type: number}|{id: (*|number), type: *}|{strip: number, page: number, type: number}|{page: (*|number), type: *}|{authCode: string, phone: string, name: string, title: string, content: string}|{phone: string}} data
 */
function POST(url, data, header = {}, ShowMsg = true) {
	const newData = new FormData();
	for (const dataKey in data) {
		if (data.hasOwnProperty(dataKey)) {
			newData.append(dataKey, data[dataKey])
		}
	}
	ShowMsg && message.loading(
		{
			content : `数据加载中...`,
			key     : url,
			duration: 10
		}
	                  )
	                  .then(() => {
		                  message.error(
			                  {
				                  content : `[${url}]加载失败.`,
				                  duration: 2,
				                  // key     : url
			                  }
		                  )
	                  })
	return fetch(
		Conf().API + url,
		{
			headers    : new Headers(header),
			method     : 'POST',
			body       : newData,
			credentials: "omit",
			mode       : "cors"
		}
	)
		.then(res => res.json())
		.then((data) => {
			ShowMsg && message.success(
				{
					content : "加载成功。",
					duration: 0.5,
					key     : url
				}
			)
			return data
		})
}
export default POST;
