/*
 * Copyright (C) 2020 qnnp. All rights reserved.
 * 项目名称：zs.jscj.edu.cn
 * 文件名称：Conf.js
 * Date: 2020/8/18 下午3:24
 * Author:qnnp
 */
function Conf() {
	return {
		API         : '//zs.jscj.edu.cn/api',
		PrePage     : 20,
		PostType    : {
			'招生计划': '1',
			'考生问答': '2',
			'录取信息': '3',
			'校园生活': '4',
			'校园风光': '5',
			'下载中心': '6',
			'公告信息': '7',
			'报考指南': '8',
			'历年数据': '17',
		},
		MenuList    : [
			{
				name: '主页',
				to  : '/'
			},
			{
				name: '招生计划',
				to  : '/PostList/招生计划'
			},
			{
				name: '考生问答',
				to  : '/PostList/考生问答'
			},
			{
				name: '录取信息',
				to  : '/PostList/录取信息'
			},
			{
				name: '校园风光',
				to  : '/PostList/校园风光'
			},
			{
				name: '校园生活',
				to  : '/PostList/校园生活'
			},
			{
				name: '下载中心',
				to  : '/PostList/下载中心'
			},
			{
				name: '信息查询',
				to  : '/Query'
			},
			// {
			// 	name: '在线咨询',
			// 	to  : '/Guestbook'
			// },
			{
				name: '关于我们',
				to  : '/AboutUs'
			},
			{
				name: '学校首页',
				to  : 'http://www.jscj.edu.cn'
			},
		],
		MobileDevice: window.innerWidth > 768,
		PUBLIC:process.env.PUBLIC_URL
	}
}
export default Conf
